<template>
  <div class="test">
    <div style="font-size: 1rem; marigin-top: 2rem">欢迎进入好用点点</div>

    <!-- <button class="btn green" @click="goOld(false)">前往老系统</button> -->
    <br />
    <button class="btn gray" type="primary" @click="signOut()">清除缓存</button>
    <br />
    <!-- <button class="btn red" @click="getWxConfig">获取微信config</button> -->

    <div style="width: 100%">
      <u-swiper :imgList="carouselImgList"></u-swiper>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { userLogin, getUserInfo } from "@utils/aRequest";
import uSwiper from "@/components/u-swiper.vue";
const prefix = "/mock";

export default {
  name: "test",
  data() {
    return {
      value: "",
      images: [
        "https://img.yzcdn.cn/vant/apple-1.jpg",
        "https://img.yzcdn.cn/vant/apple-2.jpg",
      ],
      carouselImgList: [
        "https://p1-q.mafengwo.net/s15/M00/CB/90/CoUBGV2vBDCADDQ8AA5ERr6gR8I530.jpg?imageView2%2F2%2Fw%2F680%2Fq%2F90%7CimageMogr2%2Fstrip%2Fquality%2F90",
        "https://n1-q.mafengwo.net/s15/M00/CB/CC/CoUBGV2vBD2ABVbzAA_2FYuvYY4545.jpg?imageView2%2F2%2Fw%2F680%2Fq%2F90%7CimageMogr2%2Fstrip%2Fquality%2F90",
      ],
    };
  },
  components: { uSwiper },
  created() {
    console.log("create");
    // 这里的url是在mock.js中拦截的地址
    // this.$http.get("/mock/news").then((res) => {
    //   console.log(res.data); // 打印一下响应数据
    // });
  },

  methods: {
    getWxConfig() {
      // wx.config({
      //   debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //   appId: '', // 必填，公众号的唯一标识
      //   timestamp: , // 必填，生成签名的时间戳
      //   nonceStr: '', // 必填，生成签名的随机串
      //   signature: '',// 必填，签名
      //   jsApiList: [] // 必填，需要使用的JS接口列表
      // });
    },
    goOld(value) {
      if (!value) {
        // 测试登陆老项目
        let code = localStorage.getItem("localCode")
          ? localStorage.getItem("localCode")
          : "999";
        let session = localStorage.getItem("sessionId");
        let title =
          "https://axz.youlove.com.cn/mobile-test/demo/?code=" +
          code +
          "&sessionId=" +
          session +
          "&state=#/pages/disconver/bbs/rent/rent-list";
        alert(title);
        window.location.href = title;
      } else {
        //正式
        let code = localStorage.getItem("localCode")
          ? localStorage.getItem("localCode")
          : "999";
        let session = localStorage.getItem("sessionId");
        let title =
          "https://axz.youlove.com.cn/mobile-test/demo/?code=" +
          code +
          "&state=#/pages/disconver/bbs/rent/rent-list";
        alert(title);
        window.location.href = title;
      }
    },
    signOut() {
      alert("退出登陆1");
      localStorage.removeItem("zoneId");
      localStorage.removeItem("openId");
      localStorage.removeItem("sessionId");
      localStorage.removeItem("roleData");
      localStorage.removeItem("localCode"); // 设置code

      // window.location.href = "http://weixin.youlove.com.cn"
      // let url = window.location.href;
      // let indexNum = url.indexOf("?");
      // if (indexNum) {
      //   window.location.href = url.substring(0, indexNum);
      // }
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>
<style>
.test {
  text-align: center;
}
.btn {
  width: 8rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 1rem;
  margin-top: 2rem;
}
.green {
  background-color: rgb(64, 211, 221);
}
.gray {
  background-color: rgb(245, 125, 191);
}
.red {
  background-color: rgb(109, 109, 207);
}
</style>