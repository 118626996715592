<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-25 11:55:52
 * @LastEditTime: 2020-12-29 11:45:26
-->
<template>
  <swiper :options="swiperOption"
          v-if="imgList.length"
          class="mySwiper"
          ref="mySwiper">
    <swiper-slide v-for="(item,i) in imgList"
                  :key="i"> <img :src="item.carouselUrl" /></swiper-slide>
    <div class="pagination"
         id="pagination"
         slot="pagination">
    </div>
  </swiper>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
export default {
  components: { swiper, swiperSlide },
  props: {
    imgList: {
      type: Array,
      default: []
    }
  },
  mounted () {
  },
  watch: {
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        preventClicksPropagation: true,
        preventClicks: true,
        pagination: {
          el: '.pagination',
          clickable: true
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
.mySwiper {
  // width: 55.2rem /* 1035/18.75 */;
  position: relative;
  overflow: hidden;
  padding: 0;
  /deep/.swiper-wrapper {
    .swiper-slide {
      left: 0.8rem /* 15/18.75 */;
      padding: 0 0.266667rem /* 5/18.75 */;
      display: flex;
      align-items: center;
      height: 8.426667rem /* 158/18.75 */;
      &.swiper-slide-active {
        width: 18.4rem /* 345/18.75 */ !important;
        justify-content: center;
      }
      &.swiper-slide-next,
      &.swiper-slide-prev,
      &.swiper-slide-duplicate-prev,
      &.swiper-slide-duplicate-next {
        img {
          height: 8.106667rem /* 152/18.75 */;
        }
      }
      &.swiper-slide-prev,
      &.swiper-slide-duplicate-prev {
        justify-content: flex-end;
      }
      &.swiper-slide-next,
      &.swiper-slide-duplicate-next {
        justify-content: flex-start;
      }
      img {
        width: 100%;
        height: 8.426667rem /* 158/18.75 */;
        border-radius: 0.266667rem /* 5/18.75 */;
        transition: 0.4s;
      }
    }
  }
  /deep/.pagination {
    width: 20rem /* 375/18.75 */ !important;
    height: 1.066667rem /* 20/18.75 */;
    // text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    span {
      width: 0.213333rem /* 4/18.75 */;
      height: 0.213333rem /* 4/18.75 */;
      background: #e7e7eb;
      border-radius: 50%;
      margin: 0 0.32rem /* 6/18.75 */ !important;
      transition: 0.2s;
      display: block;
      &.swiper-pagination-bullet-active {
        width: 0.533333rem /* 10/18.75 */;
        height: 0.213333rem /* 4/18.75 */;
        background: #4181ff;
        border: 0.053333rem /* 1/18.75 */ solid #2871ff;
        border-radius: 0.106667rem /* 2/18.75 */;
      }
    }
  }
}
</style>